.content {
  height: 100%;
  margin: 0 auto;
  width: 880px;
  padding-top: 23px;
}

.img {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;

  > div {
    margin-bottom: 0;
  }

  > div:first-child {
    flex: 1;
  }

  > div:last-child {
    margin-left: 20px;

    > span {
      font-size: 18px;
      font-weight: 500;
      margin-left: 18px;
    }
  }
}

.formItem {
  margin-bottom: 0;
}

.tips {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.duration {
  margin-right: 2px;
}

.price {
  margin-left: 24px;
}

.detailListItem {
  margin-bottom: 10px;
}

.card {
  margin-top: 4px;
  width: 520px;
}

.addAnchorWrap {
  display: flex;
  flex-direction: "row";
  margin-bottom: 8px;
  align-items: center;
}

.dataWrap {
  width: 46%;
  height: 500px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.addBtn {
  height: 30px;
  padding: 4px;
  border: 1px solid #d9d9d9;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.itemWrap {
  display: flex;
  flex-direction: "row";
  background-color: #f2f2f2;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  align-items: center;
  padding: 4px;
  z-index: 9999;
}

.ml8 {
  margin-left: 8px;
}
