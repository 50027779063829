.content {
  padding-top: 10px;
  height: 100%;
  background: rgb(246, 247, 249);
}

.title {
  margin-left: 12px;
  margin-bottom: 10px;
  font-size: 36px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    > span:first-child {
      width: 120px;
      text-align: right;
    }
  }
}

.chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card {
  margin-bottom: 36px;
}

.chatImg {
  margin-left: 120px;
}

.userList {
  display: flex;
  align-items: center;
  margin-left: 120px;
  flex-wrap: wrap;
}

.user {
  padding: 8px 12px;
  background: rgb(246, 247, 249);
  border-radius: 10px;

  > span:first-child {
    margin-right: 20px;
  }
}

.img {
  object-fit: contain;
}

.detailList {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.detailListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
