.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainSubStructure {
  flex: 1;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 24px;
}

.unit {
  width: 100px;
}

.add {
  margin-left: 20px;
}

.detailListItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.duration {
  margin-right: 2px;
}

.price {
  margin-left: 24px;
}

.card {
  width: 340px;
  height: 155px;

  > div:first-child {
    > div:first-child {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      > span:first-child {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
  }
}

.spaceCard {
  height: 666px;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.modalCard {
  overflow: hidden;
}

.empty {
  flex: 1;
  height: 666px;
  display: flex;
  align-items: center;
  justify-content: center;
}
